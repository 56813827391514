import React, {useEffect} from "react"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Layout/Seo"
import { PageTitle } from '../components/Components/Headings/PageTitle';



const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0,0)
}, [])

  return (
    <Layout>
      <Seo title="Kinzica Studio - Privacy Policy" />
      <PageTitle title={"Privacy Policy"} subtitle=""/>

      
    </Layout >

  )
}

export default PrivacyPolicy;


